import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FieldError from '../FieldError';
import RadioButton from '../../../../public/static/icons/radio-button.svg';
import RadioButtonChecked from '../../../../public/static/icons/radio-button-checked.svg';

import styles from './index.module.scss';

const RadioButtonGroup = ({
  options,
  label = '',
  name,
  size = 'normal',
  withIcons = false,
  onKeyDown = null,
  mutateFormValue = null,
  ...props
}) => {
  const handleKeyDown = (e, item) => {
    if (onKeyDown) {
      onKeyDown(e, item, mutateFormValue);
      return;
    }

    const enter = 13;
    const space = 32;
    if ((e.keyCode === enter || e.keyCode === space) && mutateFormValue) {
      mutateFormValue(name, item.value);
    }
  };

  const classes = [
    styles['radio-button-group'],
    styles[`radio-button-group--${size}`],
    'field-type-radio-button-group',
    `field-${name.replace('.', '_')}`,
  ];

  return (
    <div className={classes.join(' ')} role="group" aria-labelledby={`radio_button_group_${name}`}>
      {label && (
        <label
          htmlFor={`radio_button_group_${name}`}
          id={`radio_button_group_${name}`}
          className={styles['radio-button-group__label']}
        >
          {label}
        </label>
      )}

      <div className={styles['radio-button-group__buttons']} role="radiogroup" aria-label={name}>
        {options.map((item) => (
          <Field
            name={name}
            key={`${name}_${item.value}`}
            render={({ input }) => (
              <label
                htmlFor={`${name}_${item.value}`}
                tabIndex={0}
                className={`${styles['radio-button-group__button']} ${item.value === input.value ? styles['radio-button-group__button--selected'] : ''} ${item.className ? item.className : ''}`}
                aria-labelledby={`${name}_${item.value}`}
                onKeyDown={(e) => handleKeyDown(e, item)}
                aria-checked={item.value === input.value}
                role="radio"
              >
                <Field
                  component="input"
                  aria-label={item.label}
                  type="radio"
                  name={name}
                  value={item.value}
                  id={`${name}_${item.value}`}
                  title={item.value}
                  {...props}
                />
                {withIcons &&
                  (item.value === input.value ? <RadioButtonChecked /> : <RadioButton />)}
                {item.label}
              </label>
            )}
          />
        ))}
      </div>

      <FieldError name={name} />
    </div>
  );
};

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['normal', 'large']),
  withIcons: PropTypes.bool,
  onKeyDown: PropTypes.func,
  mutateFormValue: PropTypes.func,
};

export default RadioButtonGroup;
