import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import Slider from '../../../01_atoms/Slider';
import * as breakpoints from '../../../../utils/breakpoint';
import { formattedDate } from '../../../../utils/date-formatter.js';
import FormattedText from '../../../01_atoms/FormattedText';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

class BBImpactTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        // GTM tracking ID (There is no prop for this natively https://react-slick.neostack.com/docs/api/)
        navClass: 'bb-impact-timeline--navigation',
        // Other settings are being adjusted depending on a viewport.
        // See this.onWindowResize().
      },
      isInitialized: false,
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      popupItemIndex: null,
    };

    this.onPopupShow = this.onPopupShow.bind(this);
    this.onPopupHide = this.onPopupHide.bind(this);
    this.onEscKeyPress = this.onEscKeyPress.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    window.addEventListener('keydown', this.onEscKeyPress, false);
    this.onWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('keydown', this.onEscKeyPress, false);
  }

  /**
   * Shows item's content in popup.
   */
  onPopupShow(popupItemIndex) {
    this.setState({ popupItemIndex });
  }

  /**
   * Hides item's content from popup (closes popup).
   */
  onPopupHide() {
    this.setState({ popupItemIndex: null });
  }

  /**
   * Closes popup on ESC.
   */
  onEscKeyPress(event) {
    if (event.keyCode === 27) {
      this.onPopupHide();
    }
  }

  /**
   * Reacts on window resize event and states what sort of
   * screen viewport is being used.
   */
  onWindowResize() {
    const { items } = this.props;
    const { isDesktop, isTablet, isMobile } = this.state;

    // Desktop Viewport.
    if (breakpoints.isUp('lg') && !isDesktop) {
      this.setState((state) => ({
        isInitialized: true,
        isDesktop: true,
        isTablet: false,
        isMobile: false,
        sliderSettings: {
          ...state.sliderSettings,
          slidesToShow: 3,
          initialSlide: items.length - 3,
        },
      }));
    }

    // Tablet Viewport.
    if (breakpoints.isBetween('md', 'lg') && !isTablet) {
      this.setState((state) => ({
        isInitialized: true,
        isDesktop: false,
        isTablet: true,
        isMobile: false,
        sliderSettings: {
          ...state.sliderSettings,
          slidesToShow: 2,
          initialSlide: items.length - 2,
        },
      }));
    }

    // Mobile Viewport.
    if (breakpoints.isDown('md') && !isMobile) {
      this.setState((state) => ({
        isInitialized: true,
        isDesktop: false,
        isTablet: false,
        isMobile: true,
        sliderSettings: {
          ...state.sliderSettings,
          slidesToShow: 1,
          initialSlide: items.length - 1,
        },
      }));
    }
  }

  render() {
    const { title, items, behaviorSettings, uuid } = this.props;
    const { sliderSettings, isInitialized, popupItemIndex, isMobile } = this.state;

    if (!isInitialized) {
      return null;
    }

    const classes = [
      'bb',
      'bb-impact-timeline',
      generateClassNameByBehaviorSettings(behaviorSettings),
    ];

    return (
      <div className={classes.join(' ')} id={uuid}>
        <div className="container">
          {title && <h2 className="rockitt">{title}</h2>}

          <Slider {...sliderSettings}>
            {items.map((item, index) => (
              <div className="item" key={item.title}>
                {item.previewImage && (
                  <div
                    className="image bb-impact-timeline--popup"
                    onClick={() => (!isMobile ? this.onPopupShow(index) : null)}
                    onKeyDown={() => (!isMobile ? this.onPopupShow(index) : null)}
                  >
                    <img src={item.previewImage.url} alt={item.previewImage.alt} />
                    <div className="popover d-none d-md-block" />
                  </div>
                )}

                <div className={`status ${item.isActiveAppeal ? 'active' : ''}`}>
                  {item.isActiveAppeal ? 'Active appeal' : 'Completed appeal'}
                </div>

                {item.title && <div className="title">{item.title}</div>}

                {item.previewDescription && (
                  <div className="short-description">{item.previewDescription}</div>
                )}

                {item.nextLink && (
                  <Link {...item.nextLink}>
                    <Button
                      href={item.nextLink.url}
                      tag="a"
                      className="cta bb-impact-timeline--button"
                      size="small"
                    >
                      Support this appeal
                    </Button>
                  </Link>
                )}

                <div className="spot">
                  <span className="border" />

                  <span className="circle">
                    {item.isActiveAppeal && (
                      <img src="/static/icons/pink-exclamation.svg" alt="Active appeal" />
                    )}

                    {!item.isActiveAppeal && (
                      <img src="/static/icons/teal-tick.svg" alt="Successful appeal" />
                    )}
                  </span>

                  <span className="date">{formattedDate(item.timestamp)}</span>
                </div>
              </div>
            ))}
          </Slider>

          <div className="timeline">
            <div className="start" />

            <div className="end" />
          </div>

          {popupItemIndex !== null && (
            <div className="popup">
              <div className="body">
                <span
                  className="close-btn"
                  onClick={this.onPopupHide}
                  onKeyDown={this.onPopupHide}
                />

                <img
                  className="left"
                  src={items[popupItemIndex].image.url}
                  alt={items[popupItemIndex].image.alt}
                />

                <div className="content">
                  <p className="title">
                    <strong>{items[popupItemIndex].title}</strong>
                  </p>

                  <FormattedText className="description" text={items[popupItemIndex].description} />

                  {items[popupItemIndex].nextLink && (
                    <Link {...items[popupItemIndex].nextLink}>
                      <Button
                        href={items[popupItemIndex].nextLink.url}
                        tag="a"
                        className="bb-impact-timeline-popup--button"
                      >
                        Support this appeal
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

BBImpactTimeline.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      previewDescription: PropTypes.string,
      previewImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      nextLink: PropTypes.shape(linkPropTypes),
      isActiveAppeal: PropTypes.bool,
      timestamp: PropTypes.number,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBImpactTimeline.defaultProps = {
  title: '',
  behaviorSettings: null,
  uuid: null,
};

export default BBImpactTimeline;
