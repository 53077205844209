import React, { useEffect, useId } from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../../../../../hooks/useScreenSize';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';
import { formatAmount } from '../../../../../utils/currency';
import { pushViewItem as gtmPushViewItem } from '../../../../../utils/gtm';
import { getImpact } from '../../../../../utils/donationForm';

import HeroBackground from '../HeroBackground';
import { propTypes as defaultPicturePropTypes } from '../../../../01_atoms/DefaultPicture';
import FormattedText from '../../../../01_atoms/FormattedText';
import Button from '../../../../01_atoms/Button';
import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import { LinkChevron } from '../../../../02_molecules/Link';
import { Form } from '../../../../01_atoms/FormElements';
import RadioButtonGroup from '../../../../01_atoms/FormElements/RadioButtonGroup';
import { propTypes as moneyHandlePropTypes } from '../../BBHeroWithMoneyHandles/HeroMoneyHandles';
import HandshakeHeart from '../../../../../public/static/icons/handshake-heart.svg';
import Lock from '../../../../../public/static/icons/lock.svg';
import MoneyHandlesWithCustomAmount from './MoneyHandlesWithCustomAmount';

import styles from './index.module.scss';

const Background = ({
  imagePicture = null,
  videoURL = null,
  classes = [],
  uuid = null,
  children,
}) =>
  imagePicture ? (
    <HeroBackground
      uuid={uuid}
      className={classes.join(' ')}
      imagePicture={imagePicture}
      videoURL={videoURL}
    >
      {children}
    </HeroBackground>
  ) : (
    <div id={uuid} className={classes.join(' ')}>
      {children}
    </div>
  );

Background.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes),
  videoURL: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string),
  uuid: PropTypes.string,
};

const BBHeroWithDonationWidget = ({
  imagePicture = null,
  videoURL = null,
  title,
  subtitle = '',
  label = '',
  button = null,
  styling = 'default',
  currency,
  widgetTitle,
  defaultRecurrence = 'single_donation',
  optionsSingle = [],
  optionsMonthly = [],
  showDEC = false,
  impactsSingleDonation = [],
  impactsMonthlyDonation = [],
  preselectedSingle = 1,
  preselectedMonthly = 1,
  singleDonationLabel = 'One-off',
  recurringDonationLabel = 'Give monthly',
  hideImpactText = false,
  defaultImpactText = '',
  onSubmit,
  donateButton,
  behaviorSettings = null,
  uuid = null,
  // Data for GA.
  defaultAppealId,
  defaultAppealTitle,
}) => {
  const formId = useId();
  const { width } = useScreenSize();
  const isDesktop = width >= 1200;

  // Despite all widget-related handlers being almost the same as in
  // BBHeroWithMoneyHandles, the widget markup is completely different,
  // so there are not that many connecting points to create
  // a single component for both of them.
  const moneyHandles = {
    single_donation: optionsSingle.map((item, index) => ({
      value: `${index}`,
      label: formatAmount(currency, item.amount),
      ...optionsSingle[index],
    })),
    recurring_donation: optionsMonthly.map((item, index) => ({
      value: `${index}`,
      label: formatAmount(currency, item.amount),
      ...optionsMonthly[index],
    })),
  };

  // To have multiple form instances on the same page,
  // field names must be unique to not interfere with each other.
  const donationTypeName = `${formId}_donation_type`;
  const moneyHandlesName = `${formId}_money_handle`;
  const amountName = `${formId}_amount`;

  const initialValues = {
    formId: formId,
    [donationTypeName]: defaultRecurrence,
    // Form values structure always supports both single & monthly donations
    // so it's easier to switch between then and keep the correct state.
    [moneyHandlesName]: {
      single_donation: `${preselectedSingle}`,
      recurring_donation: `${preselectedMonthly}`,
      last_valid: {
        single_donation: moneyHandles.single_donation[preselectedSingle]
          ? `${moneyHandles.single_donation[preselectedSingle].amount}`
          : '',
        recurring_donation: moneyHandles.recurring_donation[preselectedMonthly]
          ? `${moneyHandles.recurring_donation[preselectedMonthly].amount}`
          : '',
      },
    },
    [amountName]: {
      single_donation: moneyHandles.single_donation[preselectedSingle]
        ? `${moneyHandles.single_donation[preselectedSingle].amount}`
        : '',
      recurring_donation: moneyHandles.recurring_donation[preselectedMonthly]
        ? `${moneyHandles.recurring_donation[preselectedMonthly].amount}`
        : '',
    },
  };

  useEffect(() => {
    if (defaultAppealId) {
      gtmPushViewItem([
        {
          item_id: defaultAppealId,
          item_name: defaultAppealTitle,
          item_category: 'Appeal',
          item_variant: defaultRecurrence,
        },
      ]);
    }
  }, [uuid, defaultAppealId, defaultAppealTitle, defaultRecurrence]);

  async function onFormSubmit(values) {
    await onSubmit(values);
  }

  const getCurrentImpact = (values) => {
    if (!values && !defaultImpactText) {
      return null;
    }

    let currentImpact;
    if (values[donationTypeName] === 'single_donation' && values[amountName].single_donation) {
      currentImpact = getImpact(+values[amountName].single_donation, impactsSingleDonation);
    }

    if (
      values[donationTypeName] === 'recurring_donation' &&
      values[amountName].recurring_donation
    ) {
      currentImpact = getImpact(+values[amountName].recurring_donation, impactsMonthlyDonation);
    }

    if (currentImpact) {
      return currentImpact.text;
    }

    // Keep the impact with the last know valid amount.
    if (
      values[donationTypeName] === 'single_donation' &&
      values[moneyHandlesName]?.last_valid?.single_donation
    ) {
      currentImpact = getImpact(
        +values[moneyHandlesName].last_valid.single_donation,
        impactsSingleDonation,
      );
    }

    if (
      values[donationTypeName] === 'recurring_donation' &&
      values[moneyHandlesName]?.last_valid?.recurring_donation
    ) {
      currentImpact = getImpact(
        +values[moneyHandlesName].last_valid.recurring_donation,
        impactsMonthlyDonation,
      );
    }

    if (currentImpact) {
      return currentImpact.text;
    }

    return defaultImpactText || null;
  };

  const classes = [
    styles['bb-hero-with-donation-widget'],
    styles[`bb-hero-with-donation-widget--${styling}`],
    ...(imagePicture ? [] : ['bb', 'bb-hero', styles['bb-hero-with-donation-widget--no-image']]),
    ...(showDEC ? [styles['bb-hero-with-donation-widget--dec']] : []),
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <Background uuid={uuid} classes={classes} imagePicture={imagePicture} videoURL={videoURL}>
      <div className={styles['bb-hero-with-donation-widget__container-wrapper']}>
        {showDEC && (
          <>
            <div className="red-strip" />
            <div className="d-lg-none">
              <img src="/static/icons/DEC-small.svg" alt="DEC logo" className="watermark" />
            </div>
            <div className="d-none d-lg-block">
              <img src="/static/icons/DEC-horizontal.svg" alt="DEC logo" className="watermark" />
            </div>
          </>
        )}
        <div className="container">
          <div className={styles['bb-hero-with-donation-widget__content']}>
            <div className={styles['bb-hero-with-donation-widget__group-text']} title="">
              {label && (
                <div className={styles['bb-hero-with-donation-widget__label']}>{label}</div>
              )}
              <FormattedText
                className={styles['bb-hero-with-donation-widget__title']}
                text={title}
                tag="h1"
              />
              {subtitle && (
                <FormattedText
                  className={styles['bb-hero-with-donation-widget__subtitle']}
                  text={subtitle}
                  tag="p"
                />
              )}

              {(!isDesktop || (button && button.nextLink)) && (
                <div className={styles['bb-hero-with-donation-widget__actions']}>
                  {!isDesktop && donateButton && donateButton.nextLink && (
                    <Link {...donateButton.nextLink}>
                      <Button
                        tag="a"
                        type={styling === 'default' ? 'primary' : 'emergency'}
                        size="large"
                        withArrow
                        href={donateButton.nextLink.url}
                      >
                        Donate now
                      </Button>
                    </Link>
                  )}

                  {button && button.nextLink && button.nextLink.url && (
                    <LinkChevron {...button} isBold />
                  )}
                </div>
              )}
            </div>

            {isDesktop && (
              <div className={styles['bb-hero-with-donation-widget__group-widget']}>
                <div className={styles['bb-hero-with-donation-widget__widget']} title="">
                  <Form
                    onSubmit={onFormSubmit}
                    initialValues={initialValues}
                    mutators={{
                      // Create a mutator which allows to modify field's value by external callbacks.
                      setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                      },
                    }}
                    subscription={{ submitting: true, values: true }}
                    render={({ handleSubmit, submitting, values, form: { mutators } }) => (
                      <form onSubmit={handleSubmit}>
                        {optionsSingle.length > 0 && optionsMonthly.length > 0 && (
                          <div className={styles['bb-hero-with-donation-widget__donation-type']}>
                            <div className={styles['bb-hero-with-donation-widget__monthly-text']}>
                              <HandshakeHeart />
                              <span>Make a difference all year long</span>
                            </div>
                            <RadioButtonGroup
                              name={donationTypeName}
                              size="large"
                              withIcons
                              mutateFormValue={mutators.setValue}
                              options={[
                                { value: 'single_donation', label: singleDonationLabel },
                                { value: 'recurring_donation', label: recurringDonationLabel },
                              ]}
                            />
                          </div>
                        )}

                        <div className={styles['bb-hero-with-donation-widget__widget-main']}>
                          <div className={styles['bb-hero-with-donation-widget__widget-title']}>
                            {widgetTitle}
                          </div>
                          <div className={styles['bb-hero-with-donation-widget__form']}>
                            <MoneyHandlesWithCustomAmount
                              formId={formId}
                              moneyHandles={moneyHandles}
                              donationType={values[donationTypeName]}
                              mutateFormValue={mutators.setValue}
                              currency={currency}
                              styling={styling}
                            />
                            <Button
                              className={`bb-hero-with-donation-widget__submit ${styles['bb-hero-with-donation-widget__submit']}`}
                              type={styling === 'default' ? 'primary' : 'emergency'}
                              size="extra-large"
                              isLoading={submitting}
                              tabIndex={0}
                              isBlock
                            >
                              Donate Now
                            </Button>
                          </div>

                          {!hideImpactText && getCurrentImpact(values) && (
                            <FormattedText
                              className={styles['bb-hero-with-donation-widget__impact-text']}
                              text={getCurrentImpact(values)}
                            />
                          )}
                        </div>
                      </form>
                    )}
                  />
                  <div className={styles['bb-hero-with-donation-widget__security-text']}>
                    <Lock />
                    Secure payment: All donations are secured by SSL encryption and protected by
                    reCaptcha.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Background>
  );
};

const impactsPropTypes = {
  amount: PropTypes.number.isRequired,
  text: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
};

BBHeroWithDonationWidget.propTypes = {
  imagePicture: PropTypes.shape(defaultPicturePropTypes),
  videoURL: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string,
    nextLink: PropTypes.shape(linkPropTypes),
  }),
  styling: PropTypes.oneOf(['default', 'emergency']),
  currency: PropTypes.oneOf(['GBP', 'EUR']).isRequired,
  widgetTitle: PropTypes.string.isRequired,
  defaultRecurrence: PropTypes.oneOf(['single_donation', 'recurring_donation']),
  optionsSingle: PropTypes.arrayOf(PropTypes.shape(moneyHandlePropTypes)),
  optionsMonthly: PropTypes.arrayOf(PropTypes.shape(moneyHandlePropTypes)),
  preselectedSingle: PropTypes.number,
  preselectedMonthly: PropTypes.number,
  singleDonationLabel: PropTypes.string,
  recurringDonationLabel: PropTypes.string,
  hideImpactText: PropTypes.bool,
  defaultImpactText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  donateButton: PropTypes.shape({
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }).isRequired,
  showDEC: PropTypes.bool,
  impactsSingleDonation: PropTypes.arrayOf(PropTypes.shape(impactsPropTypes)),
  impactsMonthlyDonation: PropTypes.arrayOf(PropTypes.shape(impactsPropTypes)),
  defaultAppealId: PropTypes.string.isRequired,
  defaultAppealTitle: PropTypes.string.isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroWithDonationWidget;
