import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

const BBInlineCTA = ({
  title = '',
  alignment = 'left',
  links = [],
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-inline-cta align-${alignment} ${generateClassNameByBehaviorSettings(
      behaviorSettings,
    )}`}
    id={uuid}
  >
    <div className="container">
      {title && (
        <p>
          <strong>{title}</strong>
        </p>
      )}

      <div className="inline-buttons">
        {links.map((link) => (
          <Link {...link.nextLink} key={link.title}>
            <Button
              tag="a"
              href={link.nextLink.url}
              isMobileBlock
              type={link.type}
              size={link.size}
              isOutlined={link.isOutlined}
              className="bb-inline-cta--button"
            >
              {link.title}
            </Button>
          </Link>
        ))}
      </div>
    </div>
  </div>
);

BBInlineCTA.propTypes = {
  title: PropTypes.string,
  alignment: PropTypes.oneOf(['left', 'center']),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      nextLink: PropTypes.shape(linkPropTypes).isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary', 'emergency', 'white-pink', 'white-green']),
      size: PropTypes.oneOf(['small', 'large']),
      isOutlined: PropTypes.bool,
    }),
  ),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBInlineCTA;
