import React from 'react';
import PropTypes from 'prop-types';

import { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import ItemWithImageHoverableSmall from '../../../02_molecules/Item/ItemWithImageHoverableSmall';
import {
  generateClassNameByBehaviorSettings,
  behaviorSettingsProps,
} from '../../../../utils/behaviorSettings';

const BBOurFocus = ({ title = '', items = [], uuid = null, behaviorSettings = null }) => (
  <div
    className={`bb bb-our-focus ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container compact-grid">
      {title && <h2>{title}</h2>}

      <div className="row">
        {items.map((item) => (
          <div className="col-6 col-lg-4" key={item.id}>
            <ItemWithImageHoverableSmall {...item} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

BBOurFocus.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.shape(picturePropTypes).isRequired,
      nextLink: PropTypes.shape(linkPropTypes),
    }),
  ),
  uuid: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
};

export default BBOurFocus;
