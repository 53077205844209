import React from 'react';
import PropTypes from 'prop-types';
import IconStat from '../../../02_molecules/IconStat';
import FrayedContainer from '../../../02_molecules/FrayedContainer';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

const BBImpactStats = ({ title = '', contents, behaviorSettings = null, uuid = null }) => (
  <div
    className={`bb bb-impact-stats ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <FrayedContainer containerClass="bb-container" title={title}>
      <div className="impact-stats-container">
        {contents.map((stat, i) => (
          <IconStat
            iconURL={stat.iconURL}
            title={stat.number}
            description={stat.description}
            key={`bb-impact-stat-${i}`} /* eslint-disable-line react/no-array-index-key */
          />
        ))}
      </div>
    </FrayedContainer>
  </div>
);

BBImpactStats.propTypes = {
  title: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      iconURL: PropTypes.string,
      number: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBImpactStats;
