import React from 'react';
import PropTypes from 'prop-types';
import { EmptyImageWithFallbackForIE as EmptyImageWithFallback } from '../EmptyImage';

const Picture = ({ className = '', media, url, ...props }) => (
  <picture className={className}>
    <source srcSet={url} media={media} />
    <EmptyImageWithFallback {...props} />
  </picture>
);

Picture.propTypes = {
  media: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Picture;
