import React from 'react';
import PropTypes from 'prop-types';
import Arrow from '../../../public/static/icons/arrow-right.svg';
import styles from './index.module.scss';

const TextButton = ({
  type = 'default',
  size = 'large',
  className = '',
  isDisabled = false,
  children = '',
  withArrow = false,
  onClick = () => {},
  onKeyDown = () => {},
  tabIndex = '0',
  tag = 'button',
}) => {
  // Temporary duplicating css module classes by global ones,
  // until importing values will be fixed on Storybook level.
  const classes = [
    className,
    styles['text-button'],
    styles[`text-button--${type}`],
    styles[`text-button--${size}`],
    'text-button',
    `text-button--${type}`,
    `text-button--${size}`,
  ];

  if (withArrow) {
    classes.push(styles['text-button--with-arrow']);
    classes.push('text-button--with-arrow');
  }

  const generatedClassName = classes.join(' ');

  // For better accessibility disabled buttons remain focusable. For that reason we have to
  // prevent default behaviour for aria-disabled buttons.
  const handleClick = (e) => {
    if (isDisabled) {
      e.preventDefault();
    } else {
      onClick(e);
    }
  };

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
      return;
    }

    const enter = 13;
    if (e.keyCode === enter) {
      onClick(e);
    }
  };

  const btnProps = {};
  if (tag === 'button') {
    btnProps.type = 'submit';
  }

  const Tag = tag;

  return (
    <Tag
      tabIndex={tabIndex}
      className={generatedClassName}
      {...btnProps}
      aria-disabled={tag !== 'a' ? isDisabled : null}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <span>{children}</span>
      {withArrow && <Arrow aria-hidden="true" />}
    </Tag>
  );
};

TextButton.propTypes = {
  /**
   * Uses `<a>` or `<button>` tags in html.
   */
  tag: PropTypes.oneOf(['button', 'a']),
  type: PropTypes.oneOf(['default', 'light']),
  size: PropTypes.oneOf(['small', 'large', 'extra-large']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDisabled: PropTypes.bool,
  withArrow: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.string,
};

export default TextButton;
