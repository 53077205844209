import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import ItemWithImage from '../../../02_molecules/Item/ItemWithImage';
import PaginatorMini, { PTPaginatorMini } from '../../../02_molecules/Paginator/PaginatorMini';
import LinkChevron from '../../../02_molecules/Link/LinkChevron';
import { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

// TODO: use CSS modules.

/**
 * Used in Documents (with previews) and Content Overview body blocks in Drupal.
 */
const BBTeaserWithPager = ({
  title,
  items,
  hasMobileScroll = false,
  button = null,
  pager = null,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-teaser-with-pager ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container">
      {(title || button || pager) && (
        <div className="upper">
          {title && <h2>{title}</h2>}

          {button && button.label && button.nextLink && (
            <LinkChevron label={button.label} nextLink={button.nextLink} />
          )}

          {pager && <PaginatorMini {...pager} />}
        </div>
      )}

      <div className={`${hasMobileScroll ? 'mobile-scroll' : ''} row`}>
        {items.map((item, index) => (
          <div
            key={item.title}
            className={`
              ${hasMobileScroll ? 'col-10' : 'col-12'}
              col-md-3
              ${index > 0 && !hasMobileScroll ? 'd-none d-md-block' : ''}
              ${index > 3 ? 'd-md-none' : ''}
            `}
          >
            <ItemWithImage {...item} />
          </div>
        ))}
      </div>

      <div className="bottom-line">
        {pager && !hasMobileScroll && <PaginatorMini {...pager} />}

        {button && button.label && button.nextLink && (
          <Link {...button.nextLink}>
            <Button
              tag="a"
              type="secondary"
              href={button.nextLink.url}
              isOutlined
              isMobileBlock
              className="bb-teaser-with-pager--button"
            >
              {button.label}
            </Button>
          </Link>
        )}
      </div>
    </div>
  </div>
);

export const PTBBTeaserWithPager = {
  title: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }),
  hasMobileScroll: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape(picturePropTypes),
      nextLink: PropTypes.shape(linkPropTypes).isRequired,
      type: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      timestamp: PropTypes.number,
      isMobileDescriptionHidden: PropTypes.bool,
    }),
  ).isRequired,
  pager: PropTypes.shape(PTPaginatorMini),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBTeaserWithPager.propTypes = PTBBTeaserWithPager;

export default BBTeaserWithPager;
