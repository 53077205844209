import React from 'react';
import PropTypes from 'prop-types';

import DefaultPicture, { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import Popup from '../../../02_molecules/Popup';
import ArrowIcon from '../../../../public/static/icons/arrows/carousel-button-outline.svg';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

class BBImageGrid extends React.Component {
  constructor(props) {
    super(props);

    // Calculate the total amount of items in the grid.
    let itemsCount = 0;
    props.rows.forEach((row) =>
      row.forEach(() => {
        itemsCount += 1;
      }),
    );

    this.state = {
      // Index of the currently opened item in the popup.
      sliderIndex: -1,
      itemsCount,
    };

    this.onImageClick = this.onImageClick.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onSlidePrevClick = this.onSlidePrevClick.bind(this);
    this.onSlideNextClick = this.onSlideNextClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false);
  }

  /**
   * Handle keyboard's key press.
   */
  onKeyPress(event) {
    const { sliderIndex } = this.state;

    // Ignore key press when popup for this component is hidden.
    if (sliderIndex === -1) {
      return;
    }

    if (event.keyCode === 27) {
      // Handle ESC key press.
      this.onPopupClose();
    } else if (event.keyCode === 37) {
      // Handle left arrow key press.
      this.onSlidePrevClick();
    } else if (event.keyCode === 39) {
      // Handle right arrow key press.
      this.onSlideNextClick();
    }
  }

  /**
   * Handles click on the image grid item.
   */
  onImageClick(clickedRowIndex, clickedItemIndex) {
    const { rows } = this.props;
    let sliderIndex = 0;
    let index = 0;
    rows.forEach((row, rowIndex) =>
      row.forEach((item, itemIndex) => {
        if (rowIndex === clickedRowIndex && itemIndex === clickedItemIndex) {
          sliderIndex = index;
        }
        index += 1;
      }),
    );

    this.setState({ sliderIndex });
  }

  /**
   * Handle request to change the slide item to the previous one.
   */
  onSlidePrevClick() {
    this.setState((state) => ({
      sliderIndex: state.sliderIndex > 0 ? state.sliderIndex - 1 : 0,
    }));
  }

  /**
   * Handle request to change the slide item to the next one.
   */
  onSlideNextClick() {
    this.setState((state) => ({
      sliderIndex:
        state.sliderIndex + 1 < state.itemsCount ? state.sliderIndex + 1 : state.sliderIndex,
    }));
  }

  /**
   * Handles popup close behavior.
   */
  onPopupClose() {
    this.setState({ sliderIndex: -1 });
  }

  render() {
    const { rows, withPopup, className, behaviorSettings, uuid } = this.props;
    const { sliderIndex, itemsCount } = this.state;
    let index = 0;

    const classes = [
      'bb',
      'bb-image-grid',
      styles['bb-image-grid'],
      className,
      generateClassNameByBehaviorSettings(behaviorSettings),
    ];

    return (
      <div className={classes.join(' ')} id={uuid}>
        <div className="container compact-grid">
          {/* The Popup Content */}
          {withPopup && sliderIndex !== -1 && (
            <Popup isVisible={sliderIndex >= 0} onPopupClose={this.onPopupClose}>
              <div className={styles['image-grid-slider']}>
                <div className="inner">
                  {rows.map((row) =>
                    row.map((item, itemIndex) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={itemIndex}
                        // eslint-disable-next-line no-plusplus
                        className={`item ${sliderIndex === index++ ? 'active' : ''}`}
                      >
                        <img src={item.fullImage.url} alt={item.fullImage.alt} />

                        {item.imageCaption && <div className="caption">{item.imageCaption}</div>}
                      </div>
                    )),
                  )}

                  <div className="d-flex d-lg-block navigation">
                    <ArrowIcon
                      className={`bb-image-grid--navigation arrow prev${
                        sliderIndex > 0 ? '' : ' disabled'
                      }`}
                      onClick={this.onSlidePrevClick}
                    />

                    <div className="counter d-lg-none">
                      {sliderIndex + 1}/{itemsCount}
                    </div>

                    <ArrowIcon
                      className={`bb-image-grid--navigation arrow next${
                        sliderIndex + 1 < itemsCount ? '' : ' disabled'
                      }`}
                      onClick={this.onSlideNextClick}
                    />
                  </div>
                </div>
              </div>
            </Popup>
          )}

          {/* The Images Grid */}
          <div className="row">
            {rows.map((row, rowIndex) =>
              row.map((item, itemIndex) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`image-grid-${rowIndex}-${itemIndex}`}
                  className={`${
                    rowIndex === 0 && itemIndex === 0 && itemsCount % 2 === 1 ? 'col-12' : 'col-6'
                  } ${row.length === 1 ? 'col-md-12' : ''}${row.length === 2 ? 'col-md-6' : ''}${
                    row.length === 3 ? 'col-md-4' : ''
                  }`}
                >
                  {item.previewImage && (
                    <div
                      className={`item bb-image-grid--image${withPopup ? ' clickable' : ''}${
                        item.imageCaption ? ' has-caption' : ''
                      }`}
                      // eslint-disable-next-line max-len
                      onClick={() => (withPopup ? this.onImageClick(rowIndex, itemIndex) : {})}
                      // eslint-disable-next-line max-len
                      onKeyPress={() => (withPopup ? this.onImageClick(rowIndex, itemIndex) : {})}
                    >
                      <DefaultPicture loading="lazy" {...item.previewImage} />

                      {item.imageCaption && (
                        <div className="caption d-none d-lg-block">{item.imageCaption}</div>
                      )}
                    </div>
                  )}
                </div>
              )),
            )}
          </div>
        </div>
      </div>
    );
  }
}

BBImageGrid.propTypes = {
  withPopup: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        previewImage: PropTypes.shape(picturePropTypes).isRequired,
        fullImage: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        imageCaption: PropTypes.string,
      }),
    ),
  ).isRequired,
  className: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

BBImageGrid.defaultProps = {
  withPopup: true,
  className: '',
  behaviorSettings: null,
  uuid: null,
};

export default BBImageGrid;
