import React from 'react';
import PropTypes from 'prop-types';
import withIE, { IEContext } from '../../05_hoc/withIE';

const EmptyImage = ({
  title = '',
  alt = '',
  width = 1920,
  height = 1080,
  className = '',
  attrWidth = null,
  attrHeight = null,
  loading = null,
}) => (
  <img
    src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${width} ${height}'%3E%3C/svg%3E`}
    width={attrWidth}
    height={attrHeight}
    alt={alt}
    title={title}
    className={className}
    loading={loading}
  />
);

EmptyImage.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  attrWidth: PropTypes.number,
  attrHeight: PropTypes.number,
  className: PropTypes.string,
  loading: PropTypes.string,
};

/**
 * <EmptyImage> with fallback to a a given src in Internet Explorer.
 */
export const EmptyImageWithFallbackForIE = withIE(
  ({ fallbackSrc, alt, width, height, title, loading }) => (
    <IEContext.Consumer>
      {({ isIE }) =>
        isIE ? (
          <img
            src={fallbackSrc}
            alt={alt}
            width={width}
            height={height}
            title={title}
            loading={loading || null}
          />
        ) : (
          <EmptyImage
            alt={alt}
            title={title}
            width={width}
            height={height}
            attrWidth={width}
            attrHeight={height}
            loading={loading}
          />
        )
      }
    </IEContext.Consumer>
  ),
);

export default EmptyImage;
