import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import ChartDonut from '../../../01_atoms/ChartDonut';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

const BBSectionWithCharts = ({
  title = '',
  button = null,
  chartData,
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb bb-section-with-charts ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container bb-container">
      {title && <h2>{title}</h2>}
      <div className="row with-chart">
        {chartData.map((data) => (
          <div key={data.title} className="col-12 col-md-3">
            <ChartDonut {...data} />
          </div>
        ))}
      </div>
      {button && button.label && button.nextLink && (
        <div className="row text-center">
          <div className="col-12">
            <Link {...button.nextLink}>
              <Button
                isMobileBlock
                type="secondary"
                isOutlined
                tag="a"
                href={button.nextLink.url}
                className="bb-section-with-charts--button"
              >
                {button.label}
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  </div>
);

BBSectionWithCharts.propTypes = {
  title: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }),
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBSectionWithCharts;
