import React from 'react';
import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import { CircularProgressbar } from 'react-circular-progressbar';

const ChartDonut = ({ percentage, title = '', colorClass = 'turquoise' }) => (
  <div className="chart chart-donut d-flex d-md-block text-md-center">
    <TrackVisibility once>
      {({ isVisible }) => (
        <CircularProgressbar
          strokeWidth={10}
          className={colorClass}
          value={isVisible ? parseFloat(percentage) : 0}
          text={`${percentage}%`}
        />
      )}
    </TrackVisibility>

    {title && (
      <div className="title">
        <mark className="d-md-none">
          <strong>{title}</strong>
        </mark>
        <div className="d-none d-md-block">{title}</div>
      </div>
    )}
  </div>
);

ChartDonut.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string,
  colorClass: PropTypes.string,
};

export default ChartDonut;
