import React from 'react';
import PropTypes from 'prop-types';
import Link, { propTypes as linkPropTypes } from '../../01_atoms/Link';
import Button from '../../01_atoms/Button';
import VisaIcon from '../../../public/static/icons/visa-box.svg';
import GpayIcon from '../../../public/static/icons/gpay-box.svg';
import ApplepayIcon from '../../../public/static/icons/apple-pay-box.svg';
import MastercardIcon from '../../../public/static/icons/mastercard-box.svg';
import PaypalIcon from '../../../public/static/icons/paypal-box.svg';

import styles from './index.module.scss';

const BlockFooter = ({ buttonDescription = '', button, variant = 'default' }) => (
  <div
    className={`${styles['block-footer']} ${
      styles[`block-footer--${variant}`] ? styles[`block-footer--${variant}`] : ''
    }`}
  >
    {buttonDescription && (
      <div className={styles['block-footer__action-prefix']}>{buttonDescription}</div>
    )}

    <div className={styles['block-footer__action']}>
      <Link {...button.nextLink}>
        <Button className="block-footer__cta-button" isBlock type="primary" tag="a">
          {button.label}
        </Button>
      </Link>
    </div>

    <div className={styles['block-footer__action-suffix']}>
      <div title="Visa">
        <VisaIcon aria-label="Visa" alt="Visa" />
      </div>
      <div title="G pay">
        <GpayIcon aria-label="G pay" alt="G pay" />
      </div>
      <div title="Apple pay">
        <ApplepayIcon aria-label="Apple pay" alt="Apple pay" />
      </div>
      <div title="Mastercard">
        <MastercardIcon aria-label="Mastercard" alt="Mastercard" />
      </div>
      <div title="PayPal">
        <PaypalIcon aria-label="PayPal" alt="PayPal" title="PayPal" />
      </div>
    </div>
  </div>
);

BlockFooter.propTypes = {
  buttonDescription: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
  }).isRequired,
  variant: PropTypes.oneOf(['default', 'white']),
};

export default BlockFooter;
